<template>
  <div class="inter-wrapper">
    <HeaderInter />
    <!-- 確認訂單 -->
    <div class="inter-content">
      <div class="container-fluid">
        <div class="inter-search">
          <div class="input-group">
            <div class="input-group-prepend">
              <select name="" class="form-control" @change="setStatus($event)">
                <option
                  v-for="(val, key) in orderStatus"
                  :key="key"
                  :value="key"
                >
                  {{ val }}
                </option>
              </select>
            </div>

            <div class="search-area-group">
              <date-picker v-model="dateRange" format="YYYY/MM/DD" type="date" :editable="false" :clearable="false" range :confirm="true" range-separator="-" @change="setDate()" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="items">
        <div v-if="items.length > 0" class="orderCard-group">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="orderCard-items"
            @click="toItemDetail(item)"
          >
            <div class="pic" />
            <div class="info-group">
              <p>訂單編號:{{ item.order_num }}</p>
              <p>訂購日期:{{ item.create_at.substring(0, 10) }}</p>
              <p>支付方式:{{ getPaynameLabel(item) }}</p>
            </div>
            <div class="state-group">
              <p :class="getStatusClass(item)">
                {{ getStatusLabel(item) }}
              </p>
              <p>{{ getPointLabel(item) }}<br>NT${{ item.fee_total|priceFormat }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="no_data">
            查無資料
          </p>
        </div>
      </div>
      <scroll-loader :loader-method="getItems" :loader-disable="!enableAutoLoad" />
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'
import { getOrderList } from '@/api/order'
import ScrollLoader from 'vue-scroll-loader/src/components/Scroll-Loader'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-tw'

export default {
  name: 'OrderList',
  components: {
    HeaderInter,
    ScrollLoader,
    DatePicker
  },
  data() {
    const startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 3)

    return {
      orderStatus: {
        0: '全部訂單',
        ready: '已成立',
        deposit: '已請款',
        deliver: '已出貨',
        refund: '已退款/取消'
        // cancel: '已取消',
        // used: '已核銷'
      },
      keyword: null,
      dateRange: [startDate, new Date()],
      selectedStatus: 0,
      page: 1,
      enableAutoLoad: false,
      items: false
    }
  },
  created() {
    this.getItems()
  },
  destroyed() {
    this.enableAutoLoad = false
  },
  mounted() {},
  methods: {
    getItems() {
      const data = {
        page: this.page++,
        paginate: process.env.VUE_APP_PAGINATE_SIZE
      }
      if (this.selectedStatus !== 0) {
        data.status = this.selectedStatus
      }
      if (this.dateRange) {
        const formatDate = (date) => {
          const formatted_date = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
          return formatted_date
        }

        data.date_start = formatDate(this.dateRange[0])
        data.date_end = formatDate(this.dateRange[1])
      }
      if (this.queryEnd) {
        data.date_end = this.queryEnd
      }
      if (this.keyword && this.keyword.replace('/', '').trim() !== '') {
        this.keyword = this.keyword.replace('/', '').trim()
        data.keyword = this.keyword
      }
      this.enableAutoLoad = false
      getOrderList(data).then((response) => {
        this.items = this.items ? this.items : []
        this.items = [...this.items, ...response.data.data]

        this.enableAutoLoad = response.data.data.length >= process.env.VUE_APP_PAGINATE_SIZE
      })
    },
    setDate() {
      this.enableAutoLoad = true
      this.page = 1
      this.items = false
      this.getItems()
    },
    setStatus(event) {
      this.enableAutoLoad = true
      this.selectedStatus = event.target.value
      this.page = 1
      this.items = false
      this.getItems()
    },
    setKeyword() {
      this.keyword = this.keyword.replace('/', '').trim()
      this.enableAutoLoad = true
      this.page = 1
      this.items = false
      this.getItems()
    },
    getStatusLabel(item) {
      // ready 訂單成立 deposit 已請款 deliver 已出貨 refund 已退款 cancel 已取消 used 已核銷
      if (item.is_cancel !== 'f' || item.is_refund !== 'f') {
        // return this.orderStatus.refund
        return '已退款'
      } else if (item.is_deliver !== 'f') {
        return '已出貨'
      } else {
        return '已成立'
      }
    },
    getStatusClass(item) {
      if (item.is_cancel !== 'f' || item.is_refund !== 'f') {
        return 'refunded'
      } else if (item.is_deliver !== 'f') {
        return 'shipped'
      } else if (item.is_deposit !== 'f') {
        return 'accepted'
      } else {
        return 'pending'
      }
    },
    toItemDetail(item) {
      this.$router.push('/order/detail/' + item.order_num)
    },
    getPaynameLabel(item) {
      const point = parseInt(item.use_point)
      return item.paytype_name_web + ((point === 0) ? '' : ' + 點數')
    },
    getPointLabel(item) {
      const point = parseInt(item.use_point)
      return (point === 0) ? '' : (point + '點')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "assets/scss/common.scss";
  .inter-search {
    margin: 0 0 15px;
    padding: 0;
    ::-webkit-datetime-edit {
      background: url(~@/assets/images/icon-date.svg) no-repeat left center;
      padding-left: 30px;
    }
    ::-webkit-datetime-edit-fields-wrapper {
      width: 100%;
      position: relative;
      right: 0px;
      width: 85%;
    }
    ::-webkit-datetime-edit-text {
      padding: 0;
    }
    // ::-webkit-datetime-edit-year-field { color: purple; }
    // ::-webkit-datetime-edit-month-field { color: blue; }
    // ::-webkit-datetime-edit-day-field { color: green; }
    ::-webkit-inner-spin-button {
      visibility: hidden;
    }
    ::-webkit-calendar-picker-indicator {
      // border: 1px solid #ccc;
      // border-radius: 2px;
      // box-shadow: inset 0 1px #fff, 0 1px #eee;
      background: #fff;
      position: relative;
      right: 0px;
      width: 15%;
    }

    .input-group {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-end;
      .input-group-prepend {
        flex: 0 0 35%;
      }
      .form-control {
        display: block;
        width: 100%;
        height: 36px;
        padding: 0.5rem 1.5rem 0.5rem;
        font-weight: 400;
        line-height: 1.5rem;
        background-clip: padding-box;
        border: 1px solid rgba($main-color, $alpha: 0.25);
        border-radius: 50px;
        -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      }
      select.form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url(~@/assets/images/arrow-down.svg) no-repeat
        calc(100% - 15px) center;
        background-size: 10px;
        border: none;
        background-color: rgba($main-color, $alpha: 1);
        color: rgba($white-color, $alpha: 1);
      }
    }
  }
  .search-area-group {
    flex: 0 0 65%;
    padding-left: 25px;
    p {
      flex: 0 0 100%;
      margin: 0 0 5px;
      padding: 0;
      color: rgba($font-color, $alpha: 1);
      text-align: right;
    }
    .search-area {
      flex: 0 0 100%;
      border: 1px solid rgba($main-color, $alpha: 0.25);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 5px 0 10px;
      .searchTerm {
        flex: 0 0 calc(100% -36px);
        height: 0px;
        height: 36px;
        margin: 0;
        border: 0;
        outline: none;
        color: rgba($font-color, $alpha: 1);
      }
      .searchTerm:focus {
        color: $sec-color;
      }
      .searchButton {
        flex: 0 0 36px;
        width: 36px;
        height: 36px;
        background: $main-color;
        color: $white-color;
        cursor: pointer;
        font-size: 1rem;
        position: absolute;
        right: 0;
        outline: none;
        margin: auto;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

      }
    }
  }

  .orderCard-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    // padding-bottom: 1.25rem;
    width: 90%;
    margin: auto;

    .orderCard-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 10px 15px;
      .pic {
        flex: 0 0 15%;
        background-color: $main-color;
        -webkit-mask: url(~@/assets/images/icon-ufo.svg) no-repeat left center;
        mask: url(~@/assets/images/icon-ufo.svg) no-repeat left center;
        height: 50px;
      }
      .pic.refunded{
        flex: 0 0 15%;
        background-color: rgba($font-color, $alpha: .5);
        -webkit-mask: url(~@/assets/images/icon-ufo.svg) no-repeat left center;
        mask: url(~@/assets/images/icon-ufo.svg) no-repeat left center;
        height: 50px;
      }

      .info-group {
        flex: 0 0 66%;
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          color: rgba($font-color, $alpha: 1);
          margin: 0;
          padding: 0;
          font-size: .85rem;
          line-height: 1.5rem;
        }
      }
      .state-group {
        flex: 0 0 19%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          color: rgba($notice-color, $alpha: 1);
          margin: 0;
          padding: 0;
          text-align: right;
          line-height: 1.5rem;
          font-size: .85rem;
        }
        p.accepted {
          border: 1px solid rgba($notice-color, $alpha: 0.65);
          color: rgba($notice-color, $alpha: 0.65);
          text-align: center;
          border-radius: 55px;
        }
        p.pending {
          background: rgba($notice-color, $alpha: 0.65);
          color: rgba($white-color, $alpha: 1);
          text-align: center;
          border-radius: 55px;
        }
        p.shipped {
          background: rgba($notice-color, $alpha: 1);
          color: rgba($white-color, $alpha: 1);
          text-align: center;
          border-radius: 55px;
        }
        p.refunded {
          background: rgba($font-color, $alpha: .5);
          color: rgba($white-color, $alpha: 1);
          text-align: center;
          border-radius: 55px;
        }
        p.refunded-text{
          color: rgba($font-color, $alpha: .5);

        }
      }
    }
    .orderCard-items:nth-child(even) {
      background: rgba($font-color, $alpha: 0.1);
    }
  }

  @media screen and (min-width: 768px) {
    .orderCard-group {
      width: 90%;

      .orderCard-items {
        .pic {
          flex: 0 0 10%;
        }
        .pic.refunded{
          flex: 0 0 10%;
        }

        .info-group {
          flex: 0 0 75%;
          padding: 5px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .state-group {
          flex: 0 0 15%;
        }
      }
    }
  }
</style>
